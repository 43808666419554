<template>
  <div id="change-password" content="main-content">
    <div class="panel-wrapper">
      <div>
        <h1 class="headline">
          Account Password Change
        </h1>
        <h2 class="subline">
          Your password must be between 8 and 30 characters, contain at least one lowercase letter, one capital letter,
          one digit and one special character.
        </h2>
      </div>
    </div>

    <div class="inner-container">
      <div v-if="step === 0">
        <button class="default-button" @click="goToStep(1)">
          Change password
        </button>
      </div>
      <form v-if="step === 1" id="change-password-form" action="" @submit.prevent="submit">
        <div class="fields">
          <form-group label="Current password" :error="errors.current_password">
            <password-field v-model="form.currentPassword" @change="validate(['current_password'])" />
          </form-group>

          <form-group label="New password" :error="errors.password">
            <password-validation-helper :show="passwordInFocus" :value="form.newPassword">
              <password-field v-model="form.newPassword" @change="validate(['password'])" />
            </password-validation-helper>
          </form-group>

          <form-group label="New password repeat" :error="errors.password_repeat">
            <password-field v-model="form.newPasswordRepeat" @change="validate(['password_repeat', 'password'])" />
          </form-group>
        </div>

        <form-group label="2FA code" v-if="$store.getters.isMfaEnabled" :error="errors.code">
          <code-input v-model="form.code" @fulfilled="validate(['code'])" style="max-width: initial"/>
        </form-group>

        <div class="modal-content-bottom-panel text-right">
          <button type="submit" class="default-button">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import FormGroup from '@/libs/forms/FormGroup'
import CodeInput from "@/libs/CodeInput";
import PasswordValidationHelper from "@/libs/PasswordValidationHelper";
import PasswordField from "@/libs/PasswordField";

export default {
  name: 'ChangePassword',
  components: {
    PasswordField,
    PasswordValidationHelper,
    CodeInput,
    FormGroup
  },
  data () {
    return {
      step: 0,
      form: {
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
        code: ''
      },

      passwordInFocus: false,
      errors: {}
    }
  },
  watch: {
    'errors': {
      handler (newVal) {
        if (newVal?.code && this.form.code.length === 6) {
          this.form.code = ''
        }
      },
      deep: true
    },
  },
  computed: {
    user: function () {
      return this.$store.state.user.data
    },
    formData () {
      const data = {
        current_password: this.form.currentPassword ?? '',
        password: this.form.newPassword ?? '',
        password_repeat: this.form.newPasswordRepeat ?? ''
      }

      if (this.$store.getters.isMfaEnabled) {
        data.code = this.form.code ?? ''
      }

      return data
    }
  },
  methods: {
    goToStep: function (step) {
      this.step = step
    },
    submit: function (attributes) {
      const data = this.formData
      const prms = {}
      const isValidate = Array.isArray(attributes) && attributes

      if (isValidate) {
        prms.validate = 1
      }

      this.$sdk.auth.changePassword(data, {
        params: prms
      }).then(response => {
        if (isValidate) {
          for (const attribute of attributes) {
            if (response.data[attribute]) {
              this.$set(this.errors, attribute, response.data[attribute])
            } else {
              this.$delete(this.errors, attribute)
            }
          }
        } else {
          if (Array.isArray(response.data) && response.data.length === 0) {
            this.goToStep(0)
            this.resetForm()
            this.$notice.success('Your password has been successfully changed.')
          } else {
            this.errors = response.data
          }
        }
      })
    },
    validate: function (attribute) {
      this.submit(attribute)
    },
    resetForm () {
      this.form = {
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
        code: ''
      }
    }
  }
}
</script>
<style lang="less">
  #change-password-form {
    max-width: 382px;

    .password-wrapper {
      position: relative;

      .visibility-icon {
        display: none;
      }

      svg {
        fill: #979797;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        right: 15px;
        cursor: pointer;
        transform: translateY(-50%);
      }
    }

    .form-group {
      margin: 20px 0;

      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 0;
      }

      .valid + .visibility-icon {
        display: block;
      }
    }
  }
</style>
