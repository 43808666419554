<template>
  <div class="password-wrapper">
    <input
        :type="type"
        class="form-control"
        v-model="val"
        :class="{valid: val}"
        @focusout="$emit('focusout', $event)"
        @focusin="$emit('focusin', $event)"
        @focus="$emit('focus', $event)"
        @mouseenter="$emit('mouseenter', $event)"
        @mouseleave="$emit('mouseleave', $event)"
        @mousemove="$emit('mousemove', $event)"
        @mouseout="$emit('mouseout', $event)"
        @mouseover="$emit('mouseover', $event)"
        @mouseup="$emit('mouseup', $event)"
        @change="$emit('change', $event)"
        :autocomplete="autocomplete"
    />
    <span class="visibility-icon" @click="toggleInputType" v-if="show">
      <icon name="ic_visibility_off" />
    </span>
    <span class="visibility-icon-off" @click="toggleInputType" v-else>
      <icon name="ic_visibility" />
    </span>
  </div>
</template>

<script type="text/javascript">
import Icon from "./Icon";

export default {
  name: 'PasswordField',
  props: {
    value: String,
    autocomplete: {
      type: String,
      default: 'on'
    }
  },
  components: {
    Icon
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    type () {
      return this.show ? 'text' : 'password'
    },
    val: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    toggleInputType () {
      this.show = !this.show
    }
  }
}
</script>

<style lang="less" scoped>
.password-wrapper
{
  position:      relative;

  .visibility-icon,
  .visibility-icon-off
  {
    display:  none;
  }

  svg
  {
    fill:     #979797FF;
    width:    24px;
    height:   24px;
    position: absolute;
    top:      50%;
    right:    15px;
    cursor:   pointer;
    transform: translateY(-50%);
  }

  .form-control {
    padding-right: 50px;

    &.valid ~ .visibility-icon,
    &.valid ~ .visibility-icon-off {
      display: block;
    }
  }
}
</style>
